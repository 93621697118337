
export default function () {

  return {
    startColor: 'white',
    startTextColor: 'primary',
    scrolledColor: 'white',
    scrolledTextColor: 'primary',
    showMenu: true,
    showShadow: true,
  };
}
